import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "What we do"
const PAGE_NAME = "what_we_do"
const WhatWeDoPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
		<div className="row">
          <div className="col-sm-12">
            <h2>Areas of involvement</h2>
			  <p>
			    The areas of engagement of AJVDC are multi-factorial and link directly or indirectly with UNESCO's 17  <a href="https://en.unesco.org/themes/education-sustainable-development">Education for Sustainable Development (ESD)</a> objectives.
			  </p>
			  <p>
			    These are achieved via the following broad objectives:
			  </p>
			  <ul>
                <li>Employment, subsistence and improved livelihood</li>
				<li>Access to essential social services</li>
				<li>Reducing the vulnerabilities of communities to the impacts of climate change </li>
				<li>Good government and the sustainable management of natural  resources and extractive industries. </li>
			  </ul>
			  <p>
			  Specifically, AJVDC works in the following areas to achieve our goals.
			  </p>
          </div>
        </div>
		
		<div className="row">
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image53"} />
          </div>
          <div className="col-sm-6">
            <h2>Advocacy</h2>
            <p>
              Advocating for the participation of young people in the process of
              stabilizing the country through rehabilitation, socio-economic revival and environmental protection.
            </p>
          </div>
        </div>
		
		
        <div className="row">
          <div className="col-sm-6">
            <h2>Training </h2>
            <ul>
              <li>
                Promoting youth innovations ideas to create and manage their own
                enterprises and to encourage entrepreneurial spirit among the
                youth.
              </li>
              <li>
                Running the Entrepreneurship Academy, short courses of 15 days
                each, to encourage ideas, and help with technical and managerial
                skills. This academy is hosted three times per year.
              </li>
              <li>
                Encouraging innovative ideas in different fields such as
                agribusiness, artisanal activities, and small scale mining.
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image28"} />
          </div>
        </div>


        <div className="row">
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image9"} />
          </div>
          <div className="col-sm-6">
            <h2>Networking</h2>
            <ul>
              <li>
                Connecting young entrepreneurs, gathering those with common
                goals in cooperatives, in their fields and area of business.
              </li>
              <li>Improving their products and services marketing.</li>
              <li>
                Promoting cross-border trade in the great lakes region (Congo,
                Rwanda, Uganda, Kenya, Tanzania, South Sudan, Burundi).
              </li>
              <li>Forming Youth Business groups.</li>
              <li>
                Promoting fair trade, increasing the chance for youth access to
                international market.
              </li>
              <li>
                Structuring the local, national and regional market for youth
                business.
              </li>
              <li>
                Allowing exchange of experiences between young entrepreneurs.
              </li>
              <li>Promoting public private markets.</li>
              <li>
                Promoting access to seed funds, crowd funding and bank credit
                for youth entrepreneurs.
              </li>
              <li>
                Providing access to finances and financial development
                institutions .
              </li>
            </ul>
          </div>
        </div>
		
		
        <div className="row">
          <div className="col-sm-6">
            <h2>Mentoring</h2>
            <ul>
              <li>
                Encouraging volunteer mentors to accompany the startups, for
                coaching.
              </li>
              <li>Organizing and attend economic forums.</li>
            </ul>
          </div>
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image29"} />
          </div>
        </div>
		
		
        <div className="row">
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image15"} />
          </div>
          <div className="col-sm-6">
            <h2>Volunteering</h2>
            <ul>
              <li>
                Encouraging youth volunteering for humanitarian and
                environmental actions, for sustainable development and peace
                building in Eastern of Congo and around the region.
              </li>
              <li>
                Forming volunteer groups such as the Sanitation Brigade, the Green Brigade and
                the Anti-rape Brigade.
              </li>
            </ul>
          </div>
        </div>
		
		
      </section>
    </Layout>
  )
}

export default WhatWeDoPage
